import React from "react";
import Trans from "tsi-common-react/src/common/Trans";
import { t } from "ttag";

import styles from "./PreferredDeliveryDateChatLink.module.scss";

export const PreferredDeliveryDateChatLink = () => {
    const ctaFmtString = t`If you do not see your desired delivery date, please <ChatLink>click to chat</ChatLink> contact our Guest Services team at 888.889.0028 or <EmailLink></EmailLink>`;
    const salesIQ: HTMLElement = document.querySelector(
        "[data-id=zsalesiq]",
    ) as HTMLElement;

    const msg = (
        <Trans
            fmtString={ctaFmtString}
            data={{
                ChatLink: (content) => (
                    <button
                        className={styles.root}
                        onClick={(e) => {
                            e.preventDefault();
                            salesIQ.click();
                        }}
                    >
                        {" "}
                        {content}
                    </button>
                ),
                EmailLink: () => (
                    <a
                        href="mailto:guestservices@sleepoutfitters.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        guestservices@sleepoutfitters.com
                    </a>
                ),
            }}
        />
    );
    return <p>{msg}</p>;
};
