import classNames from "classnames";
import React from "react";
import {
    formatDistanceWithSuffix,
    getAddress,
} from "tsi-common-react/src/apps/retail/helpers";
import { Link } from "tsi-common-react/src/common/Link";
import { MapDirectionsLink } from "tsi-common-react/src/common/MapDirectionsLink";
import RatingGraphic from "tsi-common-react/src/common/RatingGraphic";
import { RetailStoreOpenStatus } from "tsi-common-react/src/common/RetailStoreOpenStatus";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { urls } from "tsi-common-react/src/utils/urls";

import iconLocationMarker from "../../img/icons/location-marker.svg";
import iconPhoneIcon from "../../img/icons/phone-icon.svg";

import styles from "./LocationsColumnBlockTile.module.scss";

interface IOwnProps {}

interface IProps extends IOwnProps {
    store: IRetailStoreWithDistance | null;
    withMap: boolean;
    showBookApptLink: boolean;
}

interface IState {}

export class LocationsColumnBlockTile extends React.Component<IProps, IState> {
    private renderAddress() {
        if (!this.props.store) {
            return null;
        }
        const address = getAddress(this.props.store);
        return <address>{address}</address>;
    }

    private renderImage() {
        if (!this.props.store?.storefront) {
            return null;
        }

        return (
            <div className={styles.image}>
                <img
                    alt={this.props.store.storefront?.title}
                    src={this.props.store.storefront?.meta.download_url}
                    width="274"
                />
                <p className={styles.distance}>
                    <img src={iconLocationMarker} />
                    {formatDistanceWithSuffix(this.props.store.distance)}
                </p>
            </div>
        );
    }

    render() {
        const tileClassNames = classNames({
            [styles.tile]: true,
            [styles.tileWithoutMap]: !this.props.withMap,
        });
        if (!this.props.store) {
            return null;
        }
        return (
            <div className={tileClassNames}>
                {this.renderImage()}
                <div className={styles.content}>
                    <p className={styles.name}>
                        <Link href={this.props.store.web}>
                            {this.props.store.name}
                        </Link>
                    </p>
                    <div className={styles.rating}>
                        <Link
                            href={this.props.store.web}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <RatingGraphic
                                cardClass="reviews"
                                cardSize="large"
                                onlyStars={false}
                                starHasStroke={true}
                                rating={
                                    this.props.store.average_review_rating ||
                                    null
                                }
                                numReviews={this.props.store.num_reviews}
                            />
                        </Link>
                    </div>
                    {this.renderAddress()}
                    <RetailStoreOpenStatus store={this.props.store} />
                    <div className={styles.phone}>
                        <img src={iconPhoneIcon} width="14" height="20" />
                        <span>{this.props.store.phone}</span>
                    </div>
                    <div className={styles.directions}>
                        <MapDirectionsLink
                            addressParts={[
                                this.props.store.address,
                                this.props.store.address2,
                                this.props.store.city,
                                this.props.store.state,
                                this.props.store.postal,
                            ]}
                        />
                    </div>
                    {this.props.showBookApptLink && (
                        <Link
                            href={urls.pageURL("book-appointment")}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Book Appointment
                        </Link>
                    )}
                </div>
            </div>
        );
    }
}
